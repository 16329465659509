<template>
  <div>
    <div class="container mb-5">
      <div class="row">
        <div class="col-md-12 ps-0">
          <form @submit.prevent="submitForm">
            <input type="text" class="form-control mb-4" v-model="data.name" placeholder="Name">
            <input type="tel" class="form-control mb-4" v-model="data.phone" placeholder="Phone">
            <input type="email" class="form-control mb-4" v-model="data.email" placeholder="Email">
            <div class="form-check mb-2">
              <input type="checkbox" class="form-check-input" v-model="data.acceptTerms" id="acceptTerms">
              <label class="form-check-label text-white" for="acceptTerms">I have read and accept the <a target="_blank" class="text-white" href="https://www.chaseveritt.co.za/disclaimer-policy-website/">terms and conditions</a></label>
            </div>
            <div class="form-check mb-4">
              <input type="checkbox" class="form-check-input" v-model="data.receiveMarketing" id="receiveMarketing">
              <label class="form-check-label text-white" for="receiveMarketing">I want to receive marketing material</label>
            </div>
            <button type="submit" class="btn btn-primary" :disabled="!data.acceptTerms">SEND</button>
            <div class="success-message pb-2" v-if="successMessage">{{ successMessage }}</div>
            <div class="error-message pb-2" v-if="errorMessage">{{ errorMessage }}</div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data() {
    return {
      data: {
        name: "",
        email: "",
        phone: "",
        message: "",
        receiveMarketing: false,
        acceptTerms: false,
        errors: {
          name: '',
          email: '',
          phone: '',
          message: '',
        },
      },
      successMessage: '',
      errorMessage: ''
    }
  },
  methods: {
    submitForm() {
      this.data.errors = {};
      this.successMessage = '';
      this.errorMessage = ''; 

      if (!this.data.name) {
        this.data.errors.name = "Name is required";
      }

      if (!this.data.email) {
        this.data.errors.email = "Email is required";
      }

      if (!this.data.phone) {
        this.data.errors.phone = "Phone is required";
      } else if (!/^\d+$/.test(this.data.phone)) {
        this.data.errors.phone = "Phone must contain only numeric characters";
      }

      if (!this.data.message) {
        this.data.errors.message = "Message is required";
      }

      if (Object.keys(this.data.errors).length === 0) {
        axios.post("https://stratitude.co.za/api/agentForm.php", {
          name: this.data.name,
          email: this.data.email,
          phone: this.data.phone,
          message: this.data.message,
          receiveMarketing: this.data.receiveMarketing
        })
          .then(response => {
            this.successMessage = "Email has been sent and an agent will be in contact with you soon.";
            this.data.name = "";
            this.data.email = "";
            this.data.phone = "";
            this.data.message = "";
            this.data.acceptTerms = false;
            this.data.receiveMarketing = false;
          })
          .catch(error => {
            this.errorMessage = "Email sending failed.";
          });
      }
    },
  },
}

</script>

